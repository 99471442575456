import { mapCreditRatingToScore } from './src/services/home-affordability-maximizer.service';

/* PMT, one of the financial functions, calculates the payment for a loan based on constant payments and a constant interest rate.**/
export function PMT(ir, np, pv, fv, type) {
  /*
   * ir   - interest rate per month
   * np   - number of periods (months)
   * pv   - present value
   * fv   - future value
   * type - when the payments are due:
   *        0: end of the period, e.g. end of month (default)
   *        1: beginning of period
   */
  var pmt, pvif;

  fv || (fv = 0);
  type || (type = 0);

  if (ir === 0) return -(pv + fv) / np;

  pvif = Math.pow(1 + ir, np);
  pmt = (-ir * pv * (pvif + fv)) / (pvif - 1);

  if (type === 1) pmt /= 1 + ir;

  return pmt;
}

/**
 * Add symbol to input value
 */
export const decorateValueType = (type, value) => {
  if (typeof value === 'undefined') return '';

  if (type === 'currency') return currencyFormat(value);
  else {
    let percent = value.toFixed(3);
    while (
      percent.includes('.') &&
      '.0'.includes(percent[percent.length - 1])
    ) {
      percent = percent.slice(0, percent.length - 1);
    }
    return percent + '%';
  }
};

/**
 * Formatting utilities
 */

export const currencyFormat = (num, decimal) => {
  if (!num) return '$0';
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: decimal || 0,
  });
  return formatter.format(num);
};

export const debtGroupNames = [
  'Student',
  'Unsecured',
  'Mortgage',
  'Personal',
  'Unactionable',
  'Auto',
  'Mortgage - FHA',
  'Mortgage - 2nd Lien',
  'Other',
];
export const debtGroupValues = [
  'Student Loan',
  'Credit Card',
  'Mortgage',
  'Personal Loan',
  'Unactionable',
  'Auto',
  'Mortgage - FHA',
  'Mortgage - 2nd Lien',
  'Other',
];

const debtGroupLookup = debtGroupValues.reduce((prev, curr, index) => {
  prev[debtGroupNames[index]] = curr;
  return prev;
}, {});

export const formatDebtGroup = (title) => {
  return debtGroupLookup[title];
};

export const PLAN_PRICE = 9;

const CONVERSION_VALUES = {
  MORTGAGE: 25,
  SECOND_MORTGAGE: 20,
  HEI: 15,
  PERSONAL: 0.5,
  AUTO: 2.5,
  STUDENT: 1,
  SAVI: 0,
  GROWCREDIT: 1,
  BOOMPAY: 2,
  DOVLY: 0.5,
  KIKOFF: 1,
  CREDITBUILDERCARD: 1,
};

const SCORE_CONVERSION_THRESHOLD = 680;
const MORTGAGE_CONVERSION_THRESHOLD = 100000;
const SECOND_MORTGAGE_CONVERSION_THRESHOLD = 50000;

export const getConversionValue = (group, score, amount, source) => {
  var value = 0;

  const formattedGroup = group.toUpperCase();
  if (formattedGroup in CONVERSION_VALUES) {
    value = CONVERSION_VALUES[formattedGroup];
  }

  if (source && source.toUpperCase() in CONVERSION_VALUES) {
    value = CONVERSION_VALUES[source.toUpperCase()];
  }

  if (score != null && score < SCORE_CONVERSION_THRESHOLD) {
    value /= 2;
  }

  if (!['MORTGAGE', 'SECOND_MORTGAGE', 'HEI'].includes(group)) return value;

  if (typeof score === 'string') {
    score = mapCreditRatingToScore(score);
  }

  if (
    amount != null &&
    (('MORTGAGE' == group && amount < MORTGAGE_CONVERSION_THRESHOLD) ||
      ('SECOND_MORTGAGE' == group &&
        amount < SECOND_MORTGAGE_CONVERSION_THRESHOLD))
  ) {
    value /= 3;
  }

  return value;
};

export const getAnonUserId = () => {
  let id = localStorage.getItem('anon_user_id');

  if (!id) {
    id = Math.random().toString(36).substring(2);

    localStorage.setItem('anon_user_id', id);
  }

  return id;
};
